import { api, api_delete } from './api.service'
import { Fragment, useEffect, useRef, useState } from 'react'
import { Transition, Dialog } from '@headlessui/react'
import Table from './components/table';
import Confirm from './components/confirm';
import { useNavigate, useParams } from 'react-router-dom';
import { BuildingOfficeIcon, ChevronLeftIcon, HomeIcon, PlusIcon } from '@heroicons/react/24/outline';
import Button from './components/button';

export default function InvestmentRequest({ id, close }) {

  const navigate = useNavigate();
  const [values, setValues] = useState([]);
  const [data, setData] = useState({})
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  });

  useEffect(() => {
    if (id) {
      api(`${process.env.REACT_APP_API_URL}/public/${id}`).then(x => {
        setData(x.proposal);
      });
    }
  }, [])

  const submit = () => {
    api(`${process.env.REACT_APP_API_URL}/invest/commit/${id}`, { commitment: values }).then(x => {
      navigate('/app/invested-proposal/' + id)
    });
  }

  return (
    <div className="bg-white">
      <div className="text-neutral-800 font-semibold text-4xl text-center">Request To Invest</div>
      <div className="text-neutral-800 font-semibold text-4xl text-center">{data.title} - {data.location}</div>


      <div className="mt-4">
        <label htmlFor="price" className="block text-sm font-medium leading-6 text-gray-900">
          Investment
        </label>
        <div className="relative mt-2 rounded-md shadow-sm w-64">
          <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
            <span className="text-gray-500 sm:text-sm">$</span>
          </div>
          <input type="number" value={values.investmentAmount} onChange={(e) => setValues({ ...values, investmentAmount: e.target.value })}
            className="block w-full rounded-md border-0 py-1.5 pl-7 pr-3 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-neutral-600 sm:text-sm sm:leading-6"
            placeholder="0.00"
          />
        </div>
        <p className="mt-2 text-sm text-gray-500">
          How much do you want to invest?
        </p>
      </div>

      <div className="my-4">
        <label className="block text-sm font-medium text-neutral-700">
          Notes
        </label>
        <div className="mt-2">
          <textarea rows={3} value={values.notes} onChange={(e) => setValues({ ...values, notes: e.target.value })}
            className="block w-full appearance-none rounded-md border border-neutral-300 px-3 py-2 placeholder-neutral-400 shadow-sm focus:border-neutral-500 focus:outline-none focus:ring-neutral-500 sm:text-sm"
          />
        </div>
        <p className="mt-2 text-sm text-gray-500">
          Enter any notes you would like the principal investor to see.
        </p>
      </div>

      <Button text="Submit" onClick={() => { submit(); close() }}></Button>
      <Button className="ml-5 bg-neutral-400 hover:bg-neutral-500" text="Cancel" onClick={() => close()}></Button>
    </div>
  )
}

