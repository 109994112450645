import { api, api_delete } from './api.service'
import { Fragment, useEffect, useRef, useState } from 'react'
import { Transition, Dialog } from '@headlessui/react'
import Table from './components/table';
import Confirm from './components/confirm';
import { useNavigate, useParams } from 'react-router-dom';
import { BuildingOfficeIcon, CheckIcon, ChevronLeftIcon, HomeIcon, PlusIcon, XMarkIcon } from '@heroicons/react/24/outline';
import Button from './components/button';
import Proposal from './proposal';
import {
  EmailShareButton,
  FacebookShareButton,
  HatenaShareButton,
  InstapaperShareButton,
  LineShareButton,
  LinkedinShareButton,
  LivejournalShareButton,
  MailruShareButton,
  OKShareButton,
  PinterestShareButton,
  PocketShareButton,
  RedditShareButton,
  TelegramShareButton,
  TumblrShareButton,
  TwitterShareButton,
  ViberShareButton,
  VKShareButton,
  WhatsappShareButton,
  WorkplaceShareButton
} from "react-share";
import {
  EmailIcon,
  FacebookIcon,
  FacebookMessengerIcon,
  HatenaIcon,
  InstapaperIcon,
  LineIcon,
  LinkedinIcon,
  LivejournalIcon,
  MailruIcon,
  OKIcon,
  PinterestIcon,
  PocketIcon,
  RedditIcon,
  TelegramIcon,
  TumblrIcon,
  TwitterIcon,
  ViberIcon,
  VKIcon,
  WeiboIcon,
  WhatsappIcon,
  WorkplaceIcon
} from "react-share";

export default function ManageProposal() {

  const navigate = useNavigate();
  const params = useParams();
  const [values, setValues] = useState([]);
  const [commitments, setCommitments] = useState();
  const [isEditing, setIsEditing] = useState(!!!params.id)
  const [deleteOpen, setDeleteOpen] = useState(false);
  const deleteConfirm = () => {
    api_delete(`${process.env.REACT_APP_API_URL}/proposal/${params.id}`).then(x => {

    });
  }
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  });
  const submit = () => {
    api(`${process.env.REACT_APP_API_URL}/proposal`, { proposal: values }).then(x => {
      if (params.id) {
        setIsEditing(false)
        api(`${process.env.REACT_APP_API_URL}/proposal/${params.id}`).then(x => {
          setValues(x.proposal);
        });
      }
      else {
        navigate("/app")
      }
    });
  }

  useEffect(() => {
    if (params.id) {
      api(`${process.env.REACT_APP_API_URL}/proposal/${params.id}`).then(x => {
        setValues(x.proposal);
        setCommitments(x.requests);
      });
    }
  }, [])

  const requestApproval = (id, approved) => {
    api(`${process.env.REACT_APP_API_URL}/proposal/approval/${id}/${approved}`).then(x => {
      api(`${process.env.REACT_APP_API_URL}/proposal/${params.id}`).then(x => {
        setValues(x.proposal);
        setCommitments(x.requests);
      });
    });
  }

  const setStatus = (status) => {
    api(`${process.env.REACT_APP_API_URL}/proposal/set-status/${params.id}/${status}`).then(x => {
      api(`${process.env.REACT_APP_API_URL}/proposal/${params.id}`).then(x => {
        setValues(x.proposal);
        setCommitments(x.requests);
      });
    });
  }

  const raised = commitments?.filter(x => x.status === "Approved").reduce(function (a, b) { return a + b.value; }, 0)
  return (
    <>
      <Confirm open={deleteOpen} cancel={() => setDeleteOpen(false)} confirm={() => deleteConfirm()}></Confirm>
      <div onClick={() => navigate(-1)} className="relative"><div className="cursor-pointer left-10 absolute text-neutral-600 hover:text-neutral-800 font-semibold flex justify-center"><ChevronLeftIcon className="w-6 h-6 mr-2"></ChevronLeftIcon> Back</div></div>
      {!isEditing && <div className="mt-8 relative">
        <div className="absolute sm:right-0 sm:top-0 -top-10 right-5"><Button onClick={() => setStatus(values?.closed ? 'open' : 'closed')} text={values?.closed ? 'Open Proposal' : 'Close Proposal'}></Button></div>
        <div className="text-center font-semibold text-neutral-800 text-4xl sm:pt-0 pt-5">Manage Proposal</div>


        <div className="text-center font-semibold text-neutral-800 text-2xl mt-10">Investment Commitments</div>

        {(!commitments || !commitments.length > 0) && <div className="text-center font-semibold text-neutral-500">

          No investment commitments yet. Share your proposal using the links above to find investors.

        </div>}

        {commitments && <div>

          <div className="flex flex-col sm:flex-row justify-around my-5">

            <div className="p-10 rounded-lg border border-blue-500 text-center text-neutral-800 font-semibold m-5">
              <div>Amount to be Financed</div>
              <div className="text-2xl">{formatter.format(values.amountFinanced)}</div>
            </div>
            <div className="p-10 rounded-lg border border-green-500 text-center text-neutral-800 font-semibold m-5">
              <div>Raised</div>
              <div className="text-2xl">{formatter.format(raised)}</div>
            </div>

            {!values.closed && <div className="p-10 rounded-lg border border-red-500 text-center text-neutral-800 font-semibold m-5">
              <div>Remaining Required</div>
              <div className="text-2xl">{formatter.format(+(values.propertyPrice ?? 0) + +(values.upgradeCost ?? 0) - raised - +(values.amountFinanced ?? 0))}</div>
            </div>}
          </div>

          {commitments.filter(x => x.status !== "Rejected").map(x => <div className={`m-2 p-5 rounded-lg border flex flex-col ${x.status === "Approved" ? 'border-green-500' : ''}`}>
            <div className="flex justify-evenly">

              <div className="text-xl text-neutral-800 font-semibold">
                <div>{x.name}</div>
                <div className="text-lg text-neutral-600 sm:hidden">{x.email}</div>
              </div>
              <div className="hidden sm:block text-lg text-neutral-600">{x.email}</div>
              <div className="text-center text-xl text-neutral-700 font-semibold">{x.status === "Approved" ? 'Committed' : 'Wants to Invest'}: <b className="text-neutral-800">{formatter.format(x.value)}</b></div>
            </div>
            <div className="text-center mt-2 text-neutral-700">{x.notes}</div>
            {!x.status && <div className="w-full mx-auto max-w-xl flex justify-between font-bold text-2xl mt-5">

              <div onClick={() => requestApproval(x._id, "approved")} className="text-green-500 cursor-pointer hover:underline"><CheckIcon className="inline w-14 h-14"></CheckIcon>Approve</div>
              <div onClick={() => requestApproval(x._id, "rejected")} className="text-red-500 cursor-pointer hover:underline"><XMarkIcon className="inline w-14 h-14"></XMarkIcon>Reject</div>

            </div>}

          </div>)}
        </div>}
        <hr className="my-5" />
        {!values.closed && <div>
          <div className="text-center font-semibold text-neutral-800 text-2xl mt-10">Share</div>
          <div className="mt-10 grid grid-cols-3 sm:grid-cols-6 gap-4 place-items-center max-w-xl mx-auto">
            <EmailShareButton body={"Take a look at my property investment proposal on Move Up!"} subject={"Property Investment Proposal"} url={`${process.env.REACT_APP_URL}/proposal/${params.id}`}><EmailIcon className="mx-1 rounded"></EmailIcon></EmailShareButton>
            <FacebookShareButton quote={"Take a look at my property investment proposal on Move Up!"} url={`${process.env.REACT_APP_URL}/proposal/${params.id}`}><FacebookIcon className="mx-1 rounded"></FacebookIcon></FacebookShareButton>
            <TwitterShareButton title={"Take a look at my property investment proposal on Move Up!"} url={`${process.env.REACT_APP_URL}/proposal/${params.id}`}><TwitterIcon className="mx-1 rounded"></TwitterIcon></TwitterShareButton>
            <LinkedinShareButton summary={"Take a look at my property investment proposal on Move Up!"} title={"Property Investment Proposal"} url={`${process.env.REACT_APP_URL}/proposal/${params.id}`}><LinkedinIcon className="mx-1 rounded"></LinkedinIcon></LinkedinShareButton>
            <WhatsappShareButton title={"Take a look at my property investment proposal on Move Up!"} url={`${process.env.REACT_APP_URL}/proposal/${params.id}`}><WhatsappIcon className="mx-1 rounded"></WhatsappIcon></WhatsappShareButton>
            <TelegramShareButton title={"Take a look at my property investment proposal on Move Up!"} url={`${process.env.REACT_APP_URL}/proposal/${params.id}`}><TelegramIcon className="mx-1 rounded"></TelegramIcon></TelegramShareButton>
          </div>
          <div className="mx-5 text-center mt-5 mb-12 truncate"><a href={`${process.env.REACT_APP_URL}/proposal/${params.id}`} className="underline  text-xl text-blue-500 hover:text-blue-600">{`${process.env.REACT_APP_URL}/proposal/${params.id}`}</a></div>
        </div>}


        <div className="relative">
          <div onClick={() => setIsEditing(true)} className="absolute right-5 text-sm font-bold text-neutral-500 underline cursor-pointer">Edit Details</div>
          <div className="text-center font-semibold text-neutral-800 text-2xl my-5">Proposal Preview</div>

          <Proposal id={params.id}></Proposal>

        </div>


      </div>}
      {isEditing && <div className="p-10 relative">
        <div onClick={() => submit()} className="absolute right-5 text-sm font-bold text-neutral-500 underline cursor-pointer">Save Changes</div>

        <div className="text-neutral-800 font-semibold text-4xl text-center">Property Investment Proposal</div>

        <div className="text-neutral-800 font-semibold text-2xl text-center mt-5">General</div>
        <div className="grid grid-cols-1 sm:grid-cols-2 sm:gap-2 mt-4">

          <div>
            <label className="block text-sm font-medium text-neutral-700">
              Proposal Title
            </label>
            <div className="mt-2">
              <input value={values.title} onChange={(e) => setValues({ ...values, title: e.target.value })}

                className="block w-full appearance-none rounded-md border border-neutral-300 px-3 py-2 placeholder-neutral-400 shadow-sm focus:border-neutral-500 focus:outline-none focus:ring-neutral-500 sm:text-sm"
              />
            </div>
          </div>
          <div >
            <label className="block text-sm font-medium text-neutral-700">
              Target Location
            </label>
            <div className="mt-2">
              <input value={values.location} onChange={(e) => setValues({ ...values, location: e.target.value })}
                className="block w-full appearance-none rounded-md border border-neutral-300 px-3 py-2 placeholder-neutral-400 shadow-sm focus:border-neutral-500 focus:outline-none focus:ring-neutral-500 sm:text-sm"
              />
            </div>
          </div>
        </div>

        <div className="mt-4">
          <label className="block text-sm font-medium text-neutral-700">
            Description
          </label>
          <div className="mt-2">
            <textarea rows={3} value={values.description} onChange={(e) => setValues({ ...values, description: e.target.value })}
              className="block w-full appearance-none rounded-md border border-neutral-300 px-3 py-2 placeholder-neutral-400 shadow-sm focus:border-neutral-500 focus:outline-none focus:ring-neutral-500 sm:text-sm"
            />
          </div>
          <p className="mt-2 text-sm text-gray-500">
            Describe your plans for property investment.
          </p>
        </div>

        <hr className="my-3" />
        <div className="text-neutral-800 font-semibold text-2xl text-center">Costs and Returns</div>
        <div className="grid grid-cols-1 sm:grid-cols-2 sm:gap-2">

          <div className="mt-4">
            <label htmlFor="price" className="block text-sm font-medium leading-6 text-gray-900">
              Property Price
            </label>
            <div className="relative mt-2 rounded-md shadow-sm w-64">
              <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                <span className="text-gray-500 sm:text-sm">$</span>
              </div>
              <input type="number" value={values.propertyPrice} onChange={(e) => setValues({ ...values, propertyPrice: e.target.value })}
                className="block w-full rounded-md border-0 py-1.5 pl-7 pr-3 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-neutral-600 sm:text-sm sm:leading-6"
                placeholder="0.00"
              />
            </div>
            <p className="mt-2 text-sm text-gray-500">
              How much will it cost to buy the property?
            </p>
          </div>

          <div className="mt-4">
            <label htmlFor="price" className="block text-sm font-medium leading-6 text-gray-900">
              Additional Costs
            </label>
            <div className="relative mt-2 rounded-md shadow-sm w-64">
              <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                <span className="text-gray-500 sm:text-sm">$</span>
              </div>
              <input type="number" value={values.upgradeCost} onChange={(e) => setValues({ ...values, upgradeCost: e.target.value })}
                className="block w-full rounded-md border-0 py-1.5 pl-7 pr-3 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-neutral-600 sm:text-sm sm:leading-6"
                placeholder="0.00"
              />
            </div>
            <p className="mt-2 text-sm text-gray-500">
              How much will it cost to close on the property and/or other onetime expenses such as renovations?
            </p>
          </div>

        </div>

        <div className="grid grid-cols-1 sm:grid-cols-2 sm:gap-2">

          <div className="mt-4">
            <label htmlFor="price" className="block text-sm font-medium leading-6 text-gray-900">
              Amount to be Financed
            </label>
            <div className="relative mt-2 rounded-md shadow-sm w-64">
              <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                <span className="text-gray-500 sm:text-sm">$</span>
              </div>
              <input type="number" value={values.amountFinanced} onChange={(e) => setValues({ ...values, amountFinanced: e.target.value })}
                className="block w-full rounded-md border-0 py-1.5 pl-7 pr-3 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-neutral-600 sm:text-sm sm:leading-6"
                placeholder="0.00"
              />
            </div>
          </div>

          <div className="mt-4">
            <label htmlFor="price" className="block text-sm font-medium leading-6 text-gray-900">
              Who is your Lender?
            </label>
            <div className="mt-2">
              <input value={values.lender} onChange={(e) => setValues({ ...values, lender: e.target.value })}
                className="block w-full appearance-none rounded-md border border-neutral-300 px-3 py-2 placeholder-neutral-400 shadow-sm focus:border-neutral-500 focus:outline-none focus:ring-neutral-500 sm:text-sm"
              />
            </div>
          </div>

          <div className="text-neutral-700 my-3">Total Required Funding: <b>${+(values.propertyPrice ?? 0) + +(values.upgradeCost ?? 0) - +(values.amountFinanced ?? 0)}</b></div>

        </div>

        <div className="grid grid-cols-1 sm:grid-cols-2 sm:gap-2 mt-5">
          <div className="mt-4">
            <label htmlFor="price" className="block text-sm font-medium leading-6 text-gray-900">
              Expected Monthly Rent
            </label>
            <div className="relative mt-2 rounded-md shadow-sm w-64">
              <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                <span className="text-gray-500 sm:text-sm">$</span>
              </div>
              <input type="number" value={values.expectedMonthlyRent} onChange={(e) => setValues({ ...values, expectedMonthlyRent: e.target.value })}
                className="block w-full rounded-md border-0 py-1.5 pl-7 pr-3 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-neutral-600 sm:text-sm sm:leading-6"
                placeholder="0.00"
              />
            </div>
            <p className="mt-2 text-sm text-gray-500">
              What will be the rental price of this property?
            </p>
          </div>
          <div className="mt-4">
            <label htmlFor="price" className="block text-sm font-medium leading-6 text-gray-900">
              Expected Monthly Expenses
            </label>
            <div className="relative mt-2 rounded-md shadow-sm w-64">
              <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                <span className="text-gray-500 sm:text-sm">$</span>
              </div>
              <input type="number" value={values.expectedMonthlyOutgoing} onChange={(e) => setValues({ ...values, expectedMonthlyOutgoing: e.target.value })}
                className="block w-full rounded-md border-0 py-1.5 pl-7 pr-3 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-neutral-600 sm:text-sm sm:leading-6"
                placeholder="0.00"
              />
            </div>
            <p className="mt-2 text-sm text-gray-500">
              How much will it cost to rent and maintain the property?
            </p>
          </div>
          <div className="text-neutral-700 my-3">Net Monthly Income:  <b>${+(values.expectedMonthlyRent ?? 0) - +(values.expectedMonthlyOutgoing ?? 0)}</b></div>
        </div>
        <div className="grid grid-cols-1 sm:grid-cols-2 sm:gap-2 mt-5">

          <div className="mt-4">
            <label htmlFor="price" className="block text-sm font-medium leading-6 text-gray-900">
              Expected Year on Year Appreciation
            </label>
            <div className="relative mt-2 rounded-md shadow-sm w-64">

              <input type="number" value={values.expectedPercentageYoYAppreciation} onChange={(e) => setValues({ ...values, expectedPercentageYoYAppreciation: e.target.value })}
                className="block w-full rounded-md border-0 py-1.5 p-3 pr-7 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-neutral-600 sm:text-sm sm:leading-6"
                placeholder="0.00"
              />
              <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                <span className="text-gray-500 sm:text-sm">%</span>
              </div>
            </div>
            <p className="mt-2 text-sm text-gray-500">
              What percentage do you expect the property value to increase each year?
            </p>
          </div>
        </div>
        <hr className="my-3" />


        <div className="text-neutral-800 font-semibold text-2xl text-center mb-5">Example Properties</div>
        {values.exampleProperties?.map((x, i) => <div className="p-10 border border-neutral-200 rounded">
          <div>
            <label className="block text-sm font-medium text-neutral-700">
              Property
            </label>
            <div className="mt-2">
              <input value={x.property} onChange={(e) => setValues({ ...values, exampleProperties: values.exampleProperties.map((y, j) => i === j ? { ...y, property: e.target.value } : y) })}

                className="block w-full appearance-none rounded-md border border-neutral-300 px-3 py-2 placeholder-neutral-400 shadow-sm focus:border-neutral-500 focus:outline-none focus:ring-neutral-500 sm:text-sm"
              />
            </div>
          </div>
          <div className="mt-4">
            <label htmlFor="price" className="block text-sm font-medium leading-6 text-gray-900">
              Price
            </label>
            <div className="relative mt-2 rounded-md shadow-sm w-64">
              <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                <span className="text-gray-500 sm:text-sm">$</span>
              </div>
              <input type="number" value={x.price} onChange={(e) => setValues({ ...values, exampleProperties: values.exampleProperties.map((y, j) => i === j ? { ...y, price: e.target.value } : y) })}
                className="block w-full rounded-md border-0 py-1.5 pl-7 pr-3 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-neutral-600 sm:text-sm sm:leading-6"
                placeholder="0.00"
              />
            </div>
          </div>
          <div>
            <label className="block text-sm font-medium text-neutral-700">
              URL
            </label>
            <div className="mt-2">
              <input value={x.url} onChange={(e) => setValues({ ...values, exampleProperties: values.exampleProperties.map((y, j) => i === j ? { ...y, url: e.target.value } : y) })}

                className="block w-full appearance-none rounded-md border border-neutral-300 px-3 py-2 placeholder-neutral-400 shadow-sm focus:border-neutral-500 focus:outline-none focus:ring-neutral-500 sm:text-sm"
              />
            </div>
          </div>
          <div className="mt-4">
            <label className="block text-sm font-medium text-neutral-700">
              Notes
            </label>
            <div className="mt-2">
              <textarea rows={3} value={x.notes} onChange={(e) => setValues({ ...values, exampleProperties: values.exampleProperties.map((y, j) => i === j ? { ...y, notes: e.target.value } : y) })}
                className="block w-full appearance-none rounded-md border border-neutral-300 px-3 py-2 placeholder-neutral-400 shadow-sm focus:border-neutral-500 focus:outline-none focus:ring-neutral-500 sm:text-sm"
              />
            </div>
          </div>
        </div>)}

        <button onClick={() => setValues({ ...values, exampleProperties: values.exampleProperties ? values.exampleProperties.concat([{}]) : [{}] })}
          type="button"
          className="mt-5 relative block w-full rounded-lg border-2 border-dashed border-gray-300 p-12 text-center hover:border-gray-400 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
        >
          <HomeIcon className="text-neutral-800 w-12 h-12 inline"></HomeIcon><PlusIcon className="text-neutral-800 w-8 h-8 inline"></PlusIcon>
          <span className="mt-2 block text-sm font-semibold text-gray-900">Add a new property</span>
        </button>
        <hr className="my-3" />

        <div className="text-neutral-800 font-semibold text-2xl text-center mb-5">About You</div>

        <div className="mt-4">
          <label className="block text-sm font-medium text-neutral-700">
            Real Estate Agent
          </label>
          <div className="mt-2">
            <textarea rows={3} value={values.realEstateAgent} onChange={(e) => setValues({ ...values, realEstateAgent: e.target.value })}
              className="block w-full appearance-none rounded-md border border-neutral-300 px-3 py-2 placeholder-neutral-400 shadow-sm focus:border-neutral-500 focus:outline-none focus:ring-neutral-500 sm:text-sm"
            />
          </div>
          <p className="mt-2 text-sm text-gray-500">
            Who is your real estate agent?
          </p>
        </div>

        <div className="mt-4">
          <label className="block text-sm font-medium text-neutral-700">
            About The Principal Investment
          </label>
          <div className="mt-2">
            <textarea rows={3} value={values.aboutInvestor} onChange={(e) => setValues({ ...values, aboutInvestor: e.target.value })}
              className="block w-full appearance-none rounded-md border border-neutral-300 px-3 py-2 placeholder-neutral-400 shadow-sm focus:border-neutral-500 focus:outline-none focus:ring-neutral-500 sm:text-sm"
            />
          </div>
          <p className="mt-2 text-sm text-gray-500">
            Describe who you are and why you're the right person to run this investment.
          </p>
        </div>

        <div className="mt-5">
          <Button text="Save" onClick={() => submit()}></Button>
          {params.id && <Button onClick={() => setDeleteOpen(true)} className="bg-red-500 hover:bg-red-600 ml-5" text="Delete"></Button>}
        </div>
      </div>}

    </>
  )
}