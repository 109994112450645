import { api, api_delete } from './api.service'
import { Fragment, useEffect, useRef, useState } from 'react'
import { Transition, Dialog } from '@headlessui/react'
import Table from './components/table';
import Confirm from './components/confirm';
import { useNavigate, useParams } from 'react-router-dom';
import { BuildingOfficeIcon, CheckIcon, ChevronLeftIcon, HomeIcon, PlusIcon, XMarkIcon } from '@heroicons/react/24/outline';
import Button from './components/button';
import Proposal from './proposal';
import {
  EmailShareButton,
  FacebookShareButton,
  HatenaShareButton,
  InstapaperShareButton,
  LineShareButton,
  LinkedinShareButton,
  LivejournalShareButton,
  MailruShareButton,
  OKShareButton,
  PinterestShareButton,
  PocketShareButton,
  RedditShareButton,
  TelegramShareButton,
  TumblrShareButton,
  TwitterShareButton,
  ViberShareButton,
  VKShareButton,
  WhatsappShareButton,
  WorkplaceShareButton
} from "react-share";
import {
  EmailIcon,
  FacebookIcon,
  FacebookMessengerIcon,
  HatenaIcon,
  InstapaperIcon,
  LineIcon,
  LinkedinIcon,
  LivejournalIcon,
  MailruIcon,
  OKIcon,
  PinterestIcon,
  PocketIcon,
  RedditIcon,
  TelegramIcon,
  TumblrIcon,
  TwitterIcon,
  ViberIcon,
  VKIcon,
  WeiboIcon,
  WhatsappIcon,
  WorkplaceIcon
} from "react-share";

export default function InvestedProposal() {

  const navigate = useNavigate();
  const params = useParams();
  const [values, setValues] = useState([]);
  const [commitments, setCommitments] = useState();
  const [userRequests, setUserRequests] = useState()
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  });

  useEffect(() => {
    if (params.id) {
      api(`${process.env.REACT_APP_API_URL}/proposal/invested/${params.id}`).then(x => {
        setValues(x.proposal);
        setCommitments(x.requests);
        setUserRequests(x.userRequests);
      });
    }
  }, [])

  const raised = commitments?.filter(x => x.status === "Approved").reduce(function (a, b) { return a + b.value; }, 0)

  return (
    <>
      <div onClick={() => navigate(-1)} className="relative"><div className="cursor-pointer left-10 absolute text-neutral-600 hover:text-neutral-800 font-semibold flex justify-center"><ChevronLeftIcon className="w-6 h-6 mr-2"></ChevronLeftIcon> Back</div></div>
      <div className="mt-8 relative">
        <div className="text-center font-semibold text-neutral-800 text-4xl">Manage Proposal</div>
        <div className="text-center font-semibold text-neutral-600 text-xl">Proposal is now closed. In total {formatter.format(raised)} has been raised</div>

        <div className="text-center font-semibold text-neutral-800 text-2xl mt-10">Investment Commitments</div>

        {userRequests?.map(x => <div className="text-center font-semibold text-neutral-800 text-lg my-10">{x?.approvalStatus === "Approved" ? 'You have committed ' : x?.approvalStatus === "Rejected" ? 'You requested to invest ' : 'You have committed '} {formatter.format(x?.investmentAmount)}. Your request {x?.approvalStatus === "Approved" ? ' has been approved!' : x?.approvalStatus === "Rejected" ? ' has been rejected' : ' is pending.'}</div>)}

        {commitments && <div>
          <div className="flex flex-col sm:flex-row justify-around my-5">

            <div className="p-10 rounded-lg border border-blue-500 text-center text-neutral-800 font-semibold m-5">
              <div>Amount to be Financed</div>
              <div className="text-2xl">{formatter.format(values.amountFinanced)}</div>
            </div>
            <div className="p-10 rounded-lg border border-green-500 text-center text-neutral-800 font-semibold m-5">
              <div>Raised</div>
              <div className="text-2xl">{formatter.format(raised)}</div>
            </div>

            {!values.closed && <div className="p-10 rounded-lg border border-red-500 text-center text-neutral-800 font-semibold m-5">
              <div>Remaining Required</div>
              <div className="text-2xl">{formatter.format(+(values.propertyPrice ?? 0) + +(values.upgradeCost ?? 0) - raised - +(values.amountFinanced ?? 0))}</div>
            </div>}
          </div>

          {commitments.filter(x => x.status === "Approved").map(x => <div className={`m-2 p-5 rounded-lg border flex flex-col ${x.status === "Approved" ? 'border-green-500' : ''}`}>
          <div className="flex justify-evenly">

<div className="text-xl text-neutral-800 font-semibold">
  <div>{x.name}</div>
  <div className="text-lg text-neutral-600 sm:hidden">{x.email}</div>
</div>
<div className="hidden sm:block text-lg text-neutral-600">{x.email}</div>
<div className="text-center text-xl text-neutral-700 font-semibold">{x.status === "Approved" ? 'Committed' : 'Wants to Invest'}: <b className="text-neutral-800">{formatter.format(x.value)}</b></div>
</div>
          </div>)}
        </div>}
        <hr className="my-5" />
        {!values.closed && <div> <div className="text-center font-semibold text-neutral-800 text-2xl mt-10">Share</div>
        <div className="mt-10 grid grid-cols-3 sm:grid-cols-6 gap-4 place-items-center max-w-xl mx-auto">
            <EmailShareButton body={"Take a look at my property investment proposal on Move Up!"} subject={"Property Investment Proposal"} url={`${process.env.REACT_APP_URL}/proposal/${params.id}`}><EmailIcon className="mx-1 rounded"></EmailIcon></EmailShareButton>
            <FacebookShareButton quote={"Take a look at my property investment proposal on Move Up!"} url={`${process.env.REACT_APP_URL}/proposal/${params.id}`}><FacebookIcon className="mx-1 rounded"></FacebookIcon></FacebookShareButton>
            <TwitterShareButton title={"Take a look at my property investment proposal on Move Up!"} url={`${process.env.REACT_APP_URL}/proposal/${params.id}`}><TwitterIcon className="mx-1 rounded"></TwitterIcon></TwitterShareButton>
            <LinkedinShareButton summary={"Take a look at my property investment proposal on Move Up!"} title={"Property Investment Proposal"} url={`${process.env.REACT_APP_URL}/proposal/${params.id}`}><LinkedinIcon className="mx-1 rounded"></LinkedinIcon></LinkedinShareButton>
            <WhatsappShareButton title={"Take a look at my property investment proposal on Move Up!"} url={`${process.env.REACT_APP_URL}/proposal/${params.id}`}><WhatsappIcon className="mx-1 rounded"></WhatsappIcon></WhatsappShareButton>
            <TelegramShareButton title={"Take a look at my property investment proposal on Move Up!"} url={`${process.env.REACT_APP_URL}/proposal/${params.id}`}><TelegramIcon className="mx-1 rounded"></TelegramIcon></TelegramShareButton>
          </div>
          <div className="text-center mt-5 mb-12 truncate"><a href={`${process.env.REACT_APP_URL}/proposal/${params.id}`} className="underline  text-xl text-blue-500 hover:text-blue-600">{`${process.env.REACT_APP_URL}/proposal/${params.id}`}</a></div>
        </div>}

        <div className="relative">
          <div className="text-center font-semibold text-neutral-800 text-2xl my-5">Proposal Preview</div>

          <Proposal id={params.id}></Proposal>

        </div>


      </div>

    </>
  )
}