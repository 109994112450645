import { api, api_delete } from './api.service'
import { Fragment, useEffect, useRef, useState } from 'react'
import { Transition, Dialog } from '@headlessui/react'
import Table from './components/table';
import Confirm from './components/confirm';
import { useNavigate, useParams } from 'react-router-dom';
import { ChartBarIcon, PlusIcon } from '@heroicons/react/24/outline';

export default function Proposals() {

  const [data, setData] = useState([]);
  const navigate = useNavigate();
  useEffect(() => {
    api(`${process.env.REACT_APP_API_URL}/proposal`).then(x => {
      console.log(x)
      setData(x);
    });
  }, [])

  return (
    <div className="p-10">

      <div className="text-neutral-800 font-semibold text-4xl text-center my-5">Your Proposals</div>
      {(!data?.principleProposals || !data?.principleProposals.length > 0) && <p className="text-neutral-500 text-center my-3 font-semibold">You've not created any proposals. Click the button below to add your first property investment proposal.</p>}
      {data?.principleProposals?.map(x => <div>
        <div className="p-5 border text-neutral-800 rounded-lg my-2 flex justify-between">
          <div>{x.title} - {x.location}</div>
          <div onClick={() => navigate("/app/manage-proposal/" + x._id)} className="text-neutral-500 font-semibold cursor-pointer hover:text-neutral-700">Manage</div>
        </div>
      </div>)}

      <button onClick={() => navigate("/app/manage-proposal")}
        type="button"
        className="mt-5 relative block w-full rounded-lg border-2 border-dashed border-gray-300 p-8 text-center hover:border-gray-400 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
      >
        <ChartBarIcon className="text-neutral-800 w-12 h-12 inline"></ChartBarIcon><PlusIcon className="text-neutral-800 w-8 h-8 inline"></PlusIcon>
        <span className="mt-2 block text-sm font-semibold text-gray-900">Create A Property Investment Proposal</span>
      </button>

      <hr className="my-5" />
      <div className="text-neutral-800 font-semibold text-4xl text-center my-5">Your Investments</div>
      {(!data?.proposals || !data?.proposals.length > 0) && <p className="text-neutral-500 text-center my-3 font-semibold">You've not got any investments.</p>}
      {data?.proposals?.filter((value, index, array) => { return !array.findIndex(y => y._id !== value._id) == index }).map(x => <div>
        <div className="p-5 border text-neutral-800 rounded-lg my-2 flex justify-between">
          <div>{x.title} - {x.location}</div>
          <div onClick={() => navigate("/app/invested-proposal/" + x._id)} className="text-neutral-500 font-semibold cursor-pointer hover:text-neutral-700">View</div>
        </div>
      </div>)}


    </div>
  )
}