import { api, api_delete, api_no_auth } from './api.service'
import { Fragment, useEffect, useRef, useState } from 'react'
import { Transition, Dialog } from '@headlessui/react'
import Table from './components/table';
import Confirm from './components/confirm';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { BuildingOfficeIcon, ChevronLeftIcon, HomeIcon, PlusIcon } from '@heroicons/react/24/outline';
import InvestmentRequest from './investment-request';
import logo from './logo-dark.png'
import logolight from './logo.png'

export default function Proposal({ id, hideBack }) {

  const navigate = useNavigate();
  const params = useParams();
  const [values, setValues] = useState([]);
  const [years, setYears] = useState(5);
  const [name, setName] = useState()
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  });
  const isEmbedded = !!id;
  const [modalOpen, _setModalOpen] = useState(false);
  const [loggedIn, setLoggedIn] = useState(false)
  const [requests, setRequests] = useState()
  const [isClosed, setIsClosed] = useState(false)
  const [searchParams, setSearchParams] = useSearchParams();
  const setModalOpen = (val) => {
    if (loggedIn) _setModalOpen(val);
    else navigate('/register?i=' + params.id)
  }

  useEffect(() => {

    fetch(`${process.env.REACT_APP_API_URL}/proposal/log-check`, {
      method: 'GET',
      headers: new Headers({
        'Authorization': 'Bearer ' + localStorage.getItem('token'),
      })
    }).then(res => { if (res.status === 200) { setLoggedIn(true); if (searchParams.get("invest") == "true") _setModalOpen(true) } })
    if (id) {
      api_no_auth(`${process.env.REACT_APP_API_URL}/public/${id}`).then(x => {
        setValues(x.proposal);
        setName(x.name)
        setRequests(x.requests)
      });
    }
    else {
      api_no_auth(`${process.env.REACT_APP_API_URL}/public/${params.id}`).then(x => {
        setName(x.name)
        setValues(x.proposal);
        setRequests(x.requests);
        setIsClosed(x.proposal.closed)
      });
    }

  }, [])


  if (isClosed) return <div className="bg-neutral-200">

    <div className="max-w-6xl mx-auto bg-white shadow-lg flex flex-col">
      <img src={logolight} className="w-44 mx-auto my-12"></img>
      <div className="text-neutral-800 font-semibold text-2xl sm:text-2xl text-center my-10">This investment proposal is now closed and is no longer taking investment requests</div>

    </div>
  </div>

  const raised = requests?.filter(x => x.status === "Approved").reduce(function (a, b) { return a + b.value; }, 0)


  return (
    <div className="bg-neutral-200">
      {!isEmbedded && <RequestModal id={params.id} open={modalOpen} cancel={() => setModalOpen(false)}></RequestModal>}
      {!isEmbedded && !hideBack && <div onClick={() => navigate(-1)} className="relative"><div className="cursor-pointer left-10 absolute text-neutral-600 hover:text-neutral-800 font-semibold flex justify-center"><ChevronLeftIcon className="w-6 h-6 mr-2"></ChevronLeftIcon> Back</div></div>}

      <div className="max-w-6xl mx-auto bg-white shadow-lg">
        {requests && <div className="p-3 bg-blue-600 text-white text-xl">Raised <b>{formatter.format(raised)} </b>of <b>{formatter.format(+(values.propertyPrice ?? 0) + +(values.upgradeCost ?? 0) - values.amountFinanced)}</b></div>}
        <div className="pb-12 p-10" style={{ background: 'linear-gradient(155deg, rgb(0 30 143) 40%, #fff 40.1%, #fff)' }}>
          {/* <div class="w-[40%] h-full overflow-hidden inline-block absolute">
            <div class="h-[100rem] bg-blue-500 rotate-45 transform origin-top-right"></div>
          </div> */}
          <img src={logo} className="w-44 mb-5 ml-3"></img>
          <div className="text-white font-semibold text-2xl sm:text-5xl uppercase">Property Investment<br />Proposal</div>
          <div className="text-neutral-800 font-semibold text-5xl text-right mt-28 sm:mt-10">{values.title}</div>
          <div className="text-neutral-600 font-semibold text-3xl text-right mt-5">{values.location}</div>
          <div className="flex"><div className="p-5 w-64 bg-blue-500 text-white text-center font-semibold hover:bg-blue-600 cursor-pointer mx-auto shadow-md sm:float-right sm:mr-24 mt-12 sm:mt-24" onClick={() => setModalOpen(true)}>Request to Invest</div></div>
          <div className="text-neutral-600 font-semibold text-xl mt-12 sm:mt-36 uppercase">Prepared By</div>
          <div className="text-neutral-800 font-semibold text-3xl mt-2">{name}</div>
        </div>
        <div className="relative mt-12 z-20">
          <div className="h-24 w-full absolute -top-24" style={{ background: 'linear-gradient(155deg, rgba(0,0,0,0) 90%, rgb(0 30 143) 90.1%, rgb(0 30 143))' }}>
          </div>
        </div>
        <div className="relative"><div className="bg-blue-400 h-12 w-full absolute -top-5"></div></div>

        <div className="bg-blue-700 h-12 z-20 relative"></div>
        <div className="relative left-5">
          <div className="h-64 w-full absolute " style={{ background: 'linear-gradient(135deg, rgb(41 86 255) 13%, rgba(0,0,0,0) 13.1%, rgba(0,0,0,0))' }}>
          </div>
        </div>
        <div className="relative">
          <div className="h-44 w-full absolute" style={{ background: 'linear-gradient(135deg, rgb(0 30 143) 13%, rgba(0,0,0,0) 13.1%, rgba(0,0,0,0))' }}>
          </div>
        </div>

        <div className="flex justify-end px-8 sm:px-20 text-center sm:text-left">
          <div className="text-neutral-800 font-semibold text-4xl mt-20">
            Proposal
            <div className="text-neutral-500 text-xl">{values.description}</div>

          </div>
        </div>

        <div className="flex justify-start px-8 sm:px-20 text-center sm:text-left">
          <div className="text-neutral-800 font-semibold text-4xl mt-20">
            Funding Required
            <div className="w-full sm:max-w-xl">
              <div className="text-neutral-500 text-xl my-3">
                The estimate of the required cost purchase and refurbish an investment property in {values.location} is <b className="text-neutral-800">{formatter.format(+values.upgradeCost + +values.propertyPrice)}</b>
              </div>
              <div className="text-neutral-500 text-xl my-3">
                This is made up of an estimated property price of <b className="text-neutral-800">{formatter.format(values.propertyPrice)}</b> and an estimated upgrade/refurbishment cost of <b className="text-neutral-800">{formatter.format(values.upgradeCost)}</b>
              </div>
              <div className="text-neutral-500 text-xl my-3">
                <b className="text-neutral-800">{formatter.format(values.amountFinanced)} </b> will be financed {values.lender && <span>from <b className="text-neutral-800">{values.lender}</b></span>} leaving the total funding required at <b className="text-neutral-800 text-3xl">{formatter.format(+values.upgradeCost + +values.propertyPrice - +values.amountFinanced)}</b>
              </div>
            </div>

          </div>
          <div className="hidden sm:block ml-24 mt-36 h-16 p-5 w-64 bg-blue-500 text-white text-center font-semibold hover:bg-blue-600 cursor-pointer shadow-md float-right" onClick={() => setModalOpen(true)}>Commit Funds Now</div>

        </div>

        <div className="flex justify-end px-8 sm:px-20 text-center sm:text-left">
          <div>
            <div className="text-neutral-800 font-semibold text-4xl mt-20">
              Estimated Returns
            </div>
            <div className="w-full sm:max-w-xl">
              <div className=" w-full sm:w-64 my-3">
                <div className="text-center text-neutral-700 text-xs">Slide to see estimated returns for different investment lengths</div>
                <div className="flex">
                  <div className="text-center text-neutral-700">1</div>
                  <input className="w-full flex-1" min="1" max="15" step="1" type="range" value={years} onChange={(e) => setYears(e.target.value)}></input>
                  <div className="text-center text-neutral-700">15</div>

                </div>
                <div className="text-center text-neutral-700">{years} years</div>
              </div>


              <div className="text-neutral-500 text-xl my-3 font-semibold">
                The return on investment after {years} years is estimated to be <b className="text-neutral-800 text-3xl">{formatter.format(Math.round((((+values.propertyPrice * (1 + (+values.expectedPercentageYoYAppreciation / 100)) ** years) + ((+values.expectedMonthlyRent - +values.expectedMonthlyOutgoing) * (12 * years)) - +values.propertyPrice) * 100) / 100))} ({Math.round((Math.round((((+values.propertyPrice * (1 + (+values.expectedPercentageYoYAppreciation / 100)) ** years) + ((+values.expectedMonthlyRent - +values.expectedMonthlyOutgoing) * (12 * years)) - +values.propertyPrice) * 100) / 100) / (+values.upgradeCost + +values.propertyPrice) * 100) * 10) / 10}%)</b>
              </div>

              <div className="text-neutral-500 text-xl my-3 font-semibold">
                This includes a estimated appreciation of {values.expectedPercentageYoYAppreciation}% per year, which would provide returns of <b className="text-neutral-800">{formatter.format(Math.round(((+values.propertyPrice * (1 + (+values.expectedPercentageYoYAppreciation / 100)) ** years) - +values.propertyPrice) * 100) / 100)}</b> after {years} years.
              </div>

              <div className="text-neutral-500 text-xl my-3 font-semibold">
                The property will also be rented out providing a monthly income. The property will be rented out at {formatter.format(values.expectedMonthlyRent)} per month, and there will be estimated costs of {formatter.format(values.expectedMonthlyOutgoing)} per month. This leaves a monthly dividend of <b className="text-neutral-800">{formatter.format(+values.expectedMonthlyRent - +values.expectedMonthlyOutgoing)}</b>
              </div>

              <div className="text-neutral-500 text-xl my-3 font-semibold">
                Over {years} years the rental profit will be  <b className="text-neutral-800">{formatter.format(Math.round(((+values.expectedMonthlyRent - +values.expectedMonthlyOutgoing) * (12 * years)) / 100) * 100)}</b>
              </div>
            </div>
          </div>

        </div>

        <div className="flex justify-end px-8 sm:px-20 text-center sm:text-left">
          <div>
            <div className="text-neutral-800 font-semibold text-4xl mt-20">
              Capitalization Rate
            </div>
            <div className="w-full sm:max-w-xl">              

              <div className="text-neutral-500 text-xl my-3 font-semibold">
                Capitalization rate is a real estate valuation measure used to compare different investments. Although there are many variations, the cap rate is generally calculated as the ratio between the annual rental income produced by an asset to its current market value.
              </div>

              <div className="text-neutral-500 text-xl my-3 font-semibold">
                The Capitalization rate for this proposal is <b className="text-neutral-800">{(values.expectedMonthlyRent * 12) / (values.propertyPrice)}</b>
              </div>

            </div>
          </div>

        </div>



        <div className="hidden sm:block relative mt-24">
          <div className="relative -top-12 -right-5">
            <div className="h-[28rem] absolute -top-96 bg-blue-400 w-72">
            </div>
          </div>

          <div className="h-96 absolute -top-96 bg-blue-700 w-72">
          </div>

        </div>
        <div className="bg-blue-700 h-12 z-20 relative"></div>
        <div className="relative"><div className="bg-blue-400 h-8 w-full absolute -top-5"></div></div>

        <div className="relative">
          <div className="h-44 w-full absolute" style={{ background: 'linear-gradient(135deg, rgb(0 30 143) 13%, rgba(0,0,0,0) 13.1%, rgba(0,0,0,0))' }}>
          </div>
        </div>
        <div>
          <div className=" text-center px-8 sm:px-20">
            <div className="text-neutral-800 font-semibold text-4xl mt-20">
              Example Properties


            </div>
            <div className="text-neutral-500 text-xl text-center">Here are some examples of properties in {values.location}</div>
            <div className="grid grid-cols-1 sm:grid-cols-3 gap-4 mt-20">
              {values.exampleProperties?.map((x, i) => <div className="p-5 border border-neutral-200 rounded-lg">
                <div className="text-xl font-semibold text-neutral-800">{x.property}</div>
                <div className="text-2xl font-bold text-neutral-800">{formatter.format(x.price)}</div>
                <div className="text-md text-neutral-600">{x.notes}</div>
                <div className="text-blue-500 hover:text-blue-600 mt-3"><a target="_blank" href={x.url}>View Property {`→`}</a></div>
              </div>)}
            </div>
          </div>
        </div>

        <div>
          <div className=" text-center px-8 sm:px-20">
            <div className="text-neutral-800 font-semibold text-4xl mt-20">
              Real Estate Agent
            </div>
            <div className="text-neutral-500 text-xl text-center">{values.realEstateAgent ?? 'Real Estate Agent Not Yet Hired'}</div>
          </div>
        </div>

        <div>
          <div className=" text-center px-8 sm:px-20">
            <div className="text-neutral-800 font-semibold text-4xl mt-20">
              About The Principle Investor
            </div>
            <div className="text-neutral-500 text-xl text-center">{values.aboutInvestor}</div>
          </div>
        </div>

        <div className="relative mt-44">
          <div className="h-44 w-full absolute -top-44 z-20" style={{ background: 'linear-gradient(135deg, rgba(0,0,0,0) 87%, rgb(0 30 143) 87.1%, rgb(0 30 143))' }}>
          </div>
        </div>
        <div className="relative"><div className="bg-blue-400 h-8 w-full absolute -top-5"></div></div>

        <div className="bg-blue-700 h-12 relative z-20"></div>

      </div>
    </div>
  )
}

const RequestModal = ({ open, cancel, id }) => {
  return <div>
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-20" onClose={() => cancel()}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                <InvestmentRequest id={id} close={cancel}></InvestmentRequest>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  </div>

}