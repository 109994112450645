import { Routes, Route } from "react-router-dom";
import './App.css';
import Login from "./login";
import Logout from "./logout";
import RequestReset from "./request-reset";
import ResetPassword from "./reset-password";
import Layout from "./layout";
import ManageProposal from "./manage-proposal";
import Proposals from "./proposals";
import Proposal from "./proposal";
import Register from "./register";
import InvestedProposal from "./invested-proposal";
import MasterAdminLayout from "./master-admin-layout";
import MasterAdminUsers from "./master-admin-users";

export default function App() {
  return (
    <Routes>
      <Route path="/" element={<Login />} />
      <Route path="/login" element={<Login />} />
      <Route path="/register" element={<Register />} />
      <Route path="/logout" element={<Logout />} />
      <Route path="/request-reset" element={<RequestReset />} />
      <Route path="/passwordReset" element={<ResetPassword />} />
      <Route path="/proposal/:id" element={<Proposal hideBack={true} />} />

      <Route path='/app' element={<Layout />}>
        <Route path="/app" element={<Proposals />} />
        <Route path="/app/proposal/:id" element={<Proposal />} />
        <Route path="/app/manage-proposal" element={<ManageProposal />} />
        <Route path="/app/manage-proposal/:id" element={<ManageProposal />} />
        <Route path="/app/invested-proposal/:id" element={<InvestedProposal />} />
      </Route>

      <Route path='/system-admin' element={<MasterAdminLayout />}>
        <Route path="/system-admin" element={<MasterAdminUsers />} />
      </Route>
    </Routes>
  )
}
